import { Component, Vue } from 'vue-property-decorator'
import { ScheduleReport } from '@/App/Services/interfaces/admin.interface'
import { AdminService } from '@/App/Services/Admin.service'

@Component
export default class ScheduleReportsPage extends Vue {
  public isLoading = false
  public search = ''
  public headers = [
    { text: 'User', align: 'left', sortable: true, value: 'user' },
    { text: 'Report name', align: 'left', sortable: true, value: 'reportName' },
    { text: 'Type', align: 'left', sortable: true, value: 'type' },
    { text: 'interval', align: 'left', sortable: true, value: 'interval' },
    { text: 'Time interval', align: 'left', sortable: true, value: 'timeInterval' },
    { text: 'Execution time', align: 'left', sortable: true, value: 'executionTime' },
    { text: 'Days Of Week', align: 'left', sortable: true, value: 'daysOfWeek' },
    { text: 'Execution Type', align: 'left', sortable: true, value: 'executionType' },
    { text: 'Last executed', align: 'left', sortable: true, value: 'lastExecutedTime' }
  ]
  public tableData: ScheduleReport[] = []

  private async mounted() {
    try {
      this.isLoading = true

      this.tableData = await AdminService.fetchScheduleReports()
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  }
}
